/**
 * @file
 *
 * Form Input component. Used in Forms to render input element.
 * Example: <Input name="mdvip-id" label="MDVIP ID" type="text"/>
 */
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  FormEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'

import { makeSafeForCSS } from '../../Constants'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  type: string
  onBlur?: FocusEventHandler | undefined
  value?: string
  className?: string
  required?: boolean | undefined
  onChange?: ChangeEventHandler | undefined
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined
  pattern?: string | undefined
  maxLength?: number | undefined
  defaultValue?: string | number | ReadonlyArray<string> | undefined
  min?: number | string | undefined
  step?: number | string | undefined
  requiredMessage?: string | undefined
  privateData?: boolean
}

const Input: FC<InputProps> = (props) => {
  const {
    name,
    label,
    type,
    onBlur,
    value,
    className,
    required,
    onChange,
    inputMode,
    pattern,
    maxLength,
    defaultValue,
    min,
    step,
    requiredMessage,
    privateData,
  } = props

  const [message, setError] = useState('')

  useEffect(() => {
    const input = document.querySelector(`input[name="${name}"]`)
    // requiredMessage updated
    if (input) {
      if (requiredMessage) {
        ;(input as HTMLInputElement).setCustomValidity(requiredMessage)
        setError(requiredMessage)
      } else {
        ;(input as HTMLInputElement).setCustomValidity('')
        setError('')
      }
    }
  }, [requiredMessage])

  const errorCssClass = makeSafeForCSS(name)

  const displayMessage = (value: string) => {
    if (required) {
      const messageContainer = document.getElementsByClassName(errorCssClass).item(0)
      if (messageContainer !== null) {
        if (value.length == 0) {
          if (typeof requiredMessage == 'string' && requiredMessage.length > 0) {
            setError(requiredMessage)
          } else {
            setError(`${label} field is required`)
          }
        } else {
          setError('')
          const $element = document.getElementById(name)
          if ($element) {
            $element.classList.remove('element-error')
          }
        }
      }
    }
  }

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.className = ''
    if (onBlur !== undefined) {
      onBlur(e)
    }

    if (e.currentTarget.value !== '' || e.target.value !== '') {
      e.currentTarget.className = className !== undefined ? className + ' js-filled' : 'js-filled'
    }

    displayMessage(e.currentTarget.value)
  }

  const cssClass = className !== undefined ? 'form-' + type + ' ' + className : 'form-' + type

  const onInvalid = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (required) {
      ;(event.target as HTMLInputElement).setCustomValidity(message)
      displayMessage(event.currentTarget.value)
    }
  }
  const onInput = (event: FormEvent<HTMLInputElement>) => {
    ;(event.target as HTMLInputElement).setCustomValidity('')
    setError('')
  }

  const isPrivate = privateData && { 'data-private': 'lipsum' }
  return (
    <div className="text-field--wrapper">
      <div className={`inline-error ${errorCssClass}`}>{message}</div>
      <div className="elements-wrapper">
        <input
          id={name}
          name={name}
          /* eslint-disable react/jsx-props-no-spreading */
          {...isPrivate}
          type={type}
          placeholder=""
          required={required}
          onBlur={onBlurHandler}
          className={cssClass}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          inputMode={inputMode}
          pattern={pattern}
          maxLength={maxLength}
          min={min}
          step={step}
          onInvalid={onInvalid}
          onInput={onInput}
        />
        <label className={required ? 'required' : ''} htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  )
}

export default Input
