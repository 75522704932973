/**
 * @file
 *
 * Form Input Date component. Used in Forms to render input date element.
 * Example: <InputDate name="mdvip-dob" label="Date of birth"/>
 */
import Cleave from 'cleave.js/react'
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  FormEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'

import { makeSafeForCSS } from '../../Constants'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  required?: boolean | undefined
  onChange?: ChangeEventHandler | undefined
  className?: string
  value?: string
  requiredMessage?: string
  onBlur?: FocusEventHandler | undefined
  privateData?: boolean
}

const InputDate: FC<InputProps> = (props) => {
  const { name, label, required, requiredMessage, onChange, className, value, onBlur, privateData } = props

  const [message, setError] = useState('')

  useEffect(() => {
    const input = document.querySelector(`input[name="${name}"]`)
    if (input) {
      if (requiredMessage) {
        ;(input as HTMLInputElement).setCustomValidity(requiredMessage)
      } else {
        ;(input as HTMLInputElement).setCustomValidity('')
        setError('')
      }
    }
  }, [requiredMessage])

  const errorCssClass = makeSafeForCSS(name)

  const displayMessage = (value: string) => {
    if (required) {
      const messageContainer = document.getElementsByClassName(errorCssClass).item(0)
      if (messageContainer !== null) {
        if (value.length == 0) {
          if (typeof requiredMessage == 'string' && requiredMessage.length > 0) {
            setError(requiredMessage)
          } else {
            setError(`${label} field is required`)
          }
        } else {
          setError('')
          const $element = document.getElementById(name)
          if ($element) {
            $element.classList.remove('element-error')
          }
        }
      }
    }
  }

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.className = ''
    if (onBlur !== undefined) {
      onBlur(e)
    }

    if (e.currentTarget.value !== '' || e.target.value !== '') {
      e.currentTarget.className = className !== undefined ? className + ' js-filled' : 'js-filled'
    }

    displayMessage(e.currentTarget.value)
  }

  const cssClass = className !== undefined ? 'form-date ' + className : 'form-date'

  const onInvalid = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (required) {
      ;(event.target as HTMLInputElement).setCustomValidity(message)
      displayMessage(event.currentTarget.value)
    }
  }
  const onInput = (event: FormEvent<HTMLInputElement>) => {
    ;(event.target as HTMLInputElement).setCustomValidity('')
    setError('')
  }

  const isPrivate = privateData && { 'data-private': 'lipsum' }

  return (
    <div className="text-field--wrapper">
      <div className={`inline-error ${errorCssClass}`}>{message}</div>
      <Cleave
        id={name}
        name={name}
        /* eslint-disable react/jsx-props-no-spreading */
        {...isPrivate}
        placeholder=""
        onBlur={onBlurHandler}
        className={cssClass}
        required={required}
        value={value}
        options={{
          date: true,
          datePattern: ['m', 'd', 'Y'],
        }}
        onChange={onChange}
        onInput={onInput}
        onInvalid={onInvalid}
      />
      <label className={required ? 'required' : ''} htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export default InputDate
