/**
 * @file
 *
 * Verify by Invoice ID and DOB form.
 *
 * example:
 * <InvoiceForm/>
 */

import LogRocket from 'logrocket'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { useSearchParams } from 'react-router-dom'

import { RECAPTCHA_CLIENT_KEY } from '../../config/config'
import { MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML } from '../../Constants'
import useAuthenticateContext from '../../hooks/useAuthenticate'
import { initLogRocket } from '../../utils/logrocket'
import { AuthFormError } from '../form-elements/AuthFormError'
import Input from '../form-elements/Input'
import InputDate from '../form-elements/InputDate'
import Submit from '../form-elements/Submit'
import LoadingSpinner from '../misc/Spinners/LoadingSpinner'

interface InvoiceForm {
  invoiceId: string
  dob: string
}

const InvoiceForm: React.FC = () => {
  const { errorMessage, loading, setParams } = useAuthenticateContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const expired = searchParams.get('expired') === 'true'

  const initialValues = { invoiceId: '', dob: '' }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState(initialValues)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

  const [message, setMessage] = React.useState<string>('')
  const captchaRef = React.createRef<typeof ReCAPTCHA>()

  const isFormComplete =
    Object.values(formValues).every((val) => !!val) &&
    Object.values(formErrors).every((error) => !error) &&
    (!RECAPTCHA_CLIENT_KEY || recaptchaToken) &&
    !expired &&
    !message

  const resetRecaptcha = () => {
    captchaRef.current?.reset()
  }
  const resetForm = () => {
    setFormValues(initialValues)
    resetRecaptcha()
  }

  const validate = (values: InvoiceForm): boolean => {
    const errors: InvoiceForm = { invoiceId: '', dob: '' }
    if (values.invoiceId.length < 5) {
      errors.invoiceId = 'Invoice ID is too short'
    }

    setFormErrors(errors)
    return !errors.dob && !errors.invoiceId
  }

  const handleOnChange = (e: any) => {
    setSearchParams({})
    setMessage('')
    setFormErrors(initialValues)
    const { name, value } = e.currentTarget
    // if value is non-numeric, do not set the state
    if (name === 'invoiceId' && !/^\d*$/.test(value)) return

    setFormValues({ ...formValues, [name]: value })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    // Get Values from the form.
    const target = e.target as typeof e.target & {
      invoiceId: { value: string }
      dob: { value: string }
    }
    const invoiceId = target.invoiceId.value
    const dob = target.dob.value

    await initLogRocket('invoiceId', invoiceId)

    if (!validate({ invoiceId, dob })) {
      return
    }

    // Send values to API.
    setParams({
      invoiceId,
      dob,
      recaptchaToken,
      onAuthenticateError: () => {
        LogRocket.captureMessage(MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML, {
          extra: {
            invoiceId: invoiceId,
            error: MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML,
            pageName: 'Invoice Form',
          },
        })

        setMessage(MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML)
        const errorMessage = document.getElementById('error-message')
        if (errorMessage) {
          errorMessage.classList.remove('hidden')
        }
        resetForm()
      },
    })
    setMessage('')
    resetRecaptcha()
  }

  useEffect(() => {
    if (expired && errorMessage) {
      setMessage(errorMessage)
    }
  }, [expired, errorMessage])

  return (
    <React.Fragment>
      {loading && <LoadingSpinner loading={loading} />}
      <form onSubmit={onSubmit}>
        <Input
          name="invoiceId"
          privateData={true}
          value={formValues.invoiceId}
          label="Invoice #"
          className={formErrors.invoiceId ? 'element-error js-filled' : ''}
          required={true}
          type="text"
          onChange={handleOnChange}
          maxLength={16}
        />
        <InputDate
          name="dob"
          privateData={true}
          value={formValues.dob}
          className={formErrors.dob ? 'element-error js-filled' : 'form-date'}
          required={true}
          label="Date of Birth (MM/DD/YYYY)"
          onChange={handleOnChange}
          requiredMessage="Date of Birth is required."
        />
        <div
          className="error hidden"
          id="error-message"
          dangerouslySetInnerHTML={{ __html: MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML }}
        />
        <div className="error">{formErrors.invoiceId}</div>
        {RECAPTCHA_CLIENT_KEY && (
          <ReCAPTCHA ref={captchaRef} sitekey={RECAPTCHA_CLIENT_KEY} onChange={setRecaptchaToken} />
        )}
        <Submit id="mdvip-id-submit" value="Verify Account" disabled={!isFormComplete} />
        <AuthFormError message={message} />
      </form>
    </React.Fragment>
  )
}

export default InvoiceForm
