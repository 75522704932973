/**
 * @file
 *
 * Constants.
 */

export default interface Option {
  value: number | string
  label: string
}

export const MDVIP_PHONE = '1.877.866.1411'
export const MDVIP_PHONE_HREF = 'tel:1-877-866-1411'
export const MDVIP_LOGIN_LINK = 'https://login.mdvip.com'
export const MDVIP_SLOGAN = 'Secure Payment - Login Not Needed'
export const MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

export const STATES = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'DC', label: 'DC' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
]

export const MDVIP_VERIFY_ACCOUNT_ERROR_MESSAGE =
  'Additional information is needed to process payment. Please contact MDVIP Member Services at '
export const MDVIP_VERIFY_ACCOUNT_ERROR_PHONE = '1-877-866-1411'
export const MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML =
  MDVIP_VERIFY_ACCOUNT_ERROR_MESSAGE +
  ' <a href="tel:' +
  MDVIP_VERIFY_ACCOUNT_ERROR_PHONE +
  '">' +
  MDVIP_VERIFY_ACCOUNT_ERROR_PHONE +
  '</a>.'

export const VALID_CLIENT_SIDE_ERRORS = [
  'Address',
  'Address2',
  'Amount',
  'BillingZipCode',
  'City',
  'ExpirationMonth',
  'ExpirationYear',
]

export interface UTMData {
  Source_URL__c: string | null
  Web_MD_Domain__c: string | null
  UTM_Campaign__c: string | null
  UTM_Medium__c: string | null
  UTM_Source__c: string | null
  UTM_Term__c: string | null
}

/**
 * Convert the string to the safe CSS class.
 *
 * @param {string} name
 *   Any string.
 */
export function makeSafeForCSS(name: string) {
  return name.replace(/[^a-z0-9]/g, function (s: string) {
    const c = s.charCodeAt(0)
    if (c == 32) return '-'
    if (c >= 65 && c <= 90) return '_' + s.toLowerCase()
    return '__' + ('000' + c.toString(16)).slice(-4)
  })
}
