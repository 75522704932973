/**
 * @file
 *
 * Verify by Phone or E-mail and ZIP and DOB form.
 * Used in "Other" option on to Verify Account Page
 *
 * example:
 * <OtherForm/>
 */
import LogRocket from 'logrocket'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha-enterprise'
import { useSearchParams } from 'react-router-dom'

import { RECAPTCHA_CLIENT_KEY } from '../../config/config'
import { MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML } from '../../Constants'
import useAuthenticateContext from '../../hooks/useAuthenticate'
import { initLogRocket } from '../../utils/logrocket'
import { AuthFormError } from '../form-elements/AuthFormError'
import Input from '../form-elements/Input'
import InputDate from '../form-elements/InputDate'
import PhoneNumberInput from '../form-elements/PhoneNumberInput'
import Submit from '../form-elements/Submit'
import LoadingSpinner from '../misc/Spinners/LoadingSpinner'

interface OtherForm {
  phone: string
  dob: string
  zipCode: string
}

const OtherForm: React.FC = () => {
  const { errorMessage, loading, setParams } = useAuthenticateContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const expired = searchParams.get('expired') === 'true'

  const initialValues = { phone: '', dob: '', zipCode: '' }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState(initialValues)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)

  const [message, setMessage] = React.useState(MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML)
  const captchaRef = React.createRef<typeof ReCAPTCHA>()

  const isFormComplete =
    formValues.dob &&
    (formValues.phone || formValues.zipCode) &&
    Object.values(formErrors).every((error) => !error) &&
    (!RECAPTCHA_CLIENT_KEY || recaptchaToken) &&
    !expired &&
    !message

  const resetRecaptcha = () => {
    captchaRef.current?.reset()
  }
  const resetForm = () => {
    setFormValues(initialValues)
    resetRecaptcha()
  }

  const validate = (values: OtherForm) => {
    const errors: OtherForm = { phone: '', dob: '', zipCode: '' }
    const zipPattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    const phonePattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

    if (values.phone.length === 0 && values.zipCode.length === 0) {
      errors.phone = 'Either phone number or zip code is required.'
    }
    if (values.phone.length > 0 && !phonePattern.test(values.phone)) {
      errors.phone = 'Phone number is in invalid format.'
    }
    if (values.zipCode.length > 0 && !zipPattern.test(values.zipCode)) {
      errors.zipCode = ' Zip code format is invalid.'
    }

    return errors
  }

  const handleOnChange = (e: any) => {
    setSearchParams({})
    setMessage('')
    setFormErrors(initialValues)
    const { name, value } = e.currentTarget
    if (name === 'zipCode' && !/^\d{0,5}(-\d{0,4})?$/.test(value)) return

    setFormValues({ ...formValues, [name]: value })
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    // Get Values from the form.
    const target = e.target as typeof e.target & {
      phone: { value: string }
      zipCode: { value: string }
      dob: { value: string }
    }

    const zipCode = target.zipCode.value
    const phone = target.phone.value
    const dob = target.dob.value

    if (phone.length === 0) {
      await initLogRocket('zipCode', `${zipCode}-${new Date().getTime()}`)
    } else if (zipCode.length === 0) {
      await initLogRocket('phone', phone)
    } else {
      await initLogRocket('zipCode-phone', `${zipCode}:${phone}`)
    }

    if (!validate({ phone, zipCode, dob })) {
      return
    }

    const onAuthenticateError = (error: string | null) => {
      let displayMessage = MDVIP_VERIFY_ACCOUNT_DEFAULT_ERROR_HTML
      if (error !== null) {
        if (error.includes('Verification results')) {
          displayMessage = error
          const $form = document.getElementById('form_other')
          if ($form !== null) {
            const $inputs = $form.getElementsByTagName('input')
            let i = 0
            for (i; i < $inputs.length; i++) {
              // Disable all form controls.
              $inputs[i].setAttribute('disabled', 'disabled')
            }
          }
        }
      }
      LogRocket.captureMessage(displayMessage, {
        extra: {
          other: zipCode,
          error: displayMessage,
          pageName: 'Other Form',
        },
      })
      setMessage(displayMessage)
      const errorMessage = document.getElementById('error-message')
      if (errorMessage) {
        errorMessage.classList.remove('hidden')
      }
      resetForm()
    }

    // Send values to API.
    if (phone.length > 0) {
      setParams({
        phone,
        dob,
        recaptchaToken,
        onAuthenticateError,
      })
    } else {
      setParams({
        zipCode,
        dob,
        recaptchaToken,
        onAuthenticateError,
      })
    }
    setMessage('')
    resetRecaptcha()
  }

  useEffect(() => {
    if (expired && errorMessage) {
      setMessage(errorMessage)
    }
  }, [expired, errorMessage])

  return (
    <React.Fragment>
      {loading && <LoadingSpinner loading={loading} />}
      <form onSubmit={onSubmit} id="form_other">
        <div className="form-label phone-label">Either phone number or zip code is required:</div>
        <PhoneNumberInput
          name="phone"
          privateData={true}
          value={formValues.phone}
          className={formErrors.phone ? 'element-error js-filled' : ''}
          label="Phone Number/Mobile/Work"
          type="tel"
          inputMode="tel"
          onChange={handleOnChange}
          maxLength={15}
        />
        <Input
          name="zipCode"
          value={formValues.zipCode}
          className={formErrors.zipCode ? 'element-error js-filled' : ''}
          pattern="[0-9]*"
          label="Zip Code"
          type="text"
          onChange={handleOnChange}
        />
        <div className="form-label dob-label">Date of Birth is required:</div>
        <InputDate
          name="dob"
          privateData={true}
          value={formValues.dob}
          className={formErrors.dob ? 'element-error js-filled' : 'form-date'}
          required={true}
          label="Date of Birth (MM/DD/YYYY)"
          onChange={handleOnChange}
          requiredMessage="Date of Birth is required."
        />
        <div className="error hidden" id="error-message" dangerouslySetInnerHTML={{ __html: message }} />
        <div className="error">
          {formErrors.phone}
          {formErrors.zipCode}
        </div>
        {RECAPTCHA_CLIENT_KEY && (
          <ReCAPTCHA ref={captchaRef} sitekey={RECAPTCHA_CLIENT_KEY} onChange={setRecaptchaToken} />
        )}
        <Submit id="mdvip-id-submit" value="Verify Account" disabled={!isFormComplete} />
        <AuthFormError message={message} />
      </form>
    </React.Fragment>
  )
}

export default OtherForm
