/**
 * @file
 *
 * Pay Form. Used on Add Payment Info page.
 *
 * example:
 * <PayForm/>
 */
import 'react-tabs/style/react-tabs.css'

import CheckIcon from '@mui/icons-material/Check'
import Alert from '@mui/material/Alert'
import { card, cvc } from 'creditcards'
import expiration from 'creditcards/expiration'
import CardTypes from 'creditcards-types'
import { addDays } from 'date-fns'
import React, { FocusEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { accountNumber, routingNumber } from 'us-bank-account-validator'

import { ASSETS_URL } from '../../config/config'
import { MDVIP_LOGIN_LINK, MONTHS, STATES } from '../../Constants'
import Option from '../../Constants'
import {
  PaymentProfileAchAccountType,
  PaymentProfileHsaCard,
  usePaymentSettingsQuery,
  useVerifyAchInformationMutation,
  useVerifyCreditCardInformationMutation,
} from '../../generated/hooks'
import { formatDateString } from '../../utils/dateUtils'
import ErrorText from '../error'
import ConcealedNumber from '../form-elements/ConcealedNumber'
import CreditCardInput from '../form-elements/CreditCardInput'
import DropDown from '../form-elements/DropDown'
import Input from '../form-elements/Input'
import InputPayment from '../form-elements/InputPayment'
import Radio from '../form-elements/Radio'
import Submit from '../form-elements/Submit'
import InfoOutline from '../misc/InfoOutline'
import InformationSecure from '../misc/InformationSecure'
import RoutingToolTip from '../misc/RoutingToolTip'
import LoadingSpinner from '../misc/Spinners/LoadingSpinner'
import { ReviewPropState } from '../Review'
import { ReviewFormPropState } from './ReviewForm'

// add an index to this interface to make it easier to access the values
interface PayFormInterface {
  [key: string]: any
  Amount: string
  NameOnCard: string
  CardNumber: string
  CVV: string
  ExpirationMonth: string
  ExpirationYear: string
  RoutingNumber: string
  bankName: string
  AccountNumber: string
  children?: JSX.Element | JSX.Element[]
  Address: string
  Address2: string
  City: string
  State: string
  BillingZipCode: string
}

const DEFAULT_ERROR_MESSAGE = 'An Error occurred. Please try again later.'

const PayForm: React.FC = () => {
  const navigate = useNavigate()
  interface propState {
    token: string
    dob: string
    balance: string
    mdvipId: string
    estimatedPaidThrough: string
    contact_id: string
  }

  const initialValues: PayFormInterface = {
    Amount: '',
    NameOnCard: '',
    CardNumber: '',
    CVV: '',
    ExpirationMonth: '',
    ExpirationYear: '',
    RoutingNumber: '',
    bankName: '',
    AccountNumber: '',
    Address: '',
    Address2: '',
    City: '',
    State: '',
    BillingZipCode: '',
  }

  const [isSubmitButtonDisabled, toggleSubmitIsDisabled] = useState(true)
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState(initialValues)
  const [showLoader, setShowLoader] = useState(false)

  let isSubmit = false

  // Scheduled payment date is today + 1. MM/DD/YYYY
  const scheduledDate = addDays(new Date(), 1)
  const scheduledDateFormatted = formatDateString(scheduledDate)

  // Year dropdown formation.
  const year = scheduledDate.getFullYear()
  const [message, setMessage] = React.useState('')
  const [cardType, setCardType] = React.useState('')

  const years = Array.from(new Array(20), (label, value) => {
    const $year = value + year
    const obj: Option = { value: $year - 2000, label: $year.toString() }
    return obj
  })

  // Bank Payment Mutation.
  // add error renamed
  const [verifyAchInformation, { error: verifyAchInformationError }] = useVerifyAchInformationMutation({
    fetchPolicy: 'no-cache',
  })
  // Credit Card Payment Mutation.
  const [verifyCreditCardInformation, { error: verifyCreditCardInformationError }] =
    useVerifyCreditCardInformationMutation({ fetchPolicy: 'no-cache' })

  const { data, error: verifyPaymentSettingsError } = usePaymentSettingsQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    // handle apollo errors
    if (verifyAchInformationError || verifyCreditCardInformationError || verifyPaymentSettingsError) {
      const error = verifyAchInformationError || verifyCreditCardInformationError || verifyPaymentSettingsError
      // get the error message
      const errorMessage = error?.message || DEFAULT_ERROR_MESSAGE

      showLoader && setShowLoader(false)
      setMessage(errorMessage)
    }
  }, [verifyAchInformationError, verifyCreditCardInformationError, verifyPaymentSettingsError])

  const location = useLocation()
  const { mdvipId, token, dob, balance, estimatedPaidThrough, contact_id } = location.state as propState
  /**
   * Payment information form submission.
   *
   * @param {object} e
   *  Event.
   */
  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setFormErrors(validate(formValues))
    if (isSubmit) {
      !message && setShowLoader(true)
      // Get Bank Values from the form.
      const Ach = e.target as typeof e.target & {
        AccountNumber: { value: string }
        RoutingNumber: { value: string }
        NameOnCard: { value: string }
      }

      // Amount to pay, Bank Name, is Checking or Savings account.
      const AmountBankBlock = e.target as typeof e.target & {
        Amount: { value: number }
        bankName: { value: string }
        Checking: { value: number }
      }

      // Get Credit card values from the form.
      const Card = e.target as typeof e.target & {
        CardNumber: { value: string }
        CVV: { value: string }
        ExpirationMonth: { value: string }
        ExpirationYear: { value: string }
        HSA: { value: PaymentProfileHsaCard }
        NameOnCard: { value: string }
      }

      // Billing Address.
      const BillingAddress = e.target as typeof e.target & {
        Address: { value: string }
        Address2: { value: string }
        City: { value: string }
        State: { value: string }
        BillingZipCode: { value: string }
      }

      const Address = BillingAddress.Address?.value.trim() || ''
      const Address2 = BillingAddress.Address2?.value.trim() || ''
      const City = BillingAddress.City?.value.trim() || ''
      const State = BillingAddress.State?.value || ''
      const BillingZipCode = BillingAddress.BillingZipCode?.value || ''

      const AccountNumber = Ach.AccountNumber?.value || ''
      const RoutingNumber = Ach.RoutingNumber?.value || ''
      // need extra parsing for fancy money formatting.
      const Amount = AmountBankBlock.Amount?.value.toString().replace(/\$/g, '').replace(/,/g, '') || '0'
      const bankName = AmountBankBlock.bankName?.value || ''
      const Checking = AmountBankBlock.Checking?.value || 0
      const achNameOnCard = Ach.NameOnCard.value || ''

      const CardNumber = Card.CardNumber?.value.trim().replace(/\s+/g, '') || ''
      const CVV = Card.CVV?.value.trim() || ''
      const ExpirationMonth = Card.ExpirationMonth?.value || ''
      const ExpirationYear = Card.ExpirationYear?.value || ''
      const ccNameOnCard = Card.NameOnCard?.value || ''
      const HSA = Card.HSA.value
      // If Bank Account form fields are filled.
      if (AccountNumber.length > 0 && RoutingNumber.length > 0) {
        const AccountType: PaymentProfileAchAccountType = Checking
          ? PaymentProfileAchAccountType.Checking
          : PaymentProfileAchAccountType.Savings
        const lastSixDigitsAccountNumber = AccountNumber.substring(AccountNumber.length - 6)
        const lastNineDigitsRoutingNumber = RoutingNumber.substring(RoutingNumber.length - 9)
        const paymentMethod = bankName + ' ' + AccountType + ' Account ending in ' + lastSixDigitsAccountNumber
        // Run query. If Bank is confirmed, go to review page.
        verifyAchInformation({
          variables: { input: { AccountNumber: AccountNumber, RoutingNumber: RoutingNumber } },
          onCompleted(data) {
            if (data.verifyACHInformation.__typename === 'VerifyACHInformationSuccess') {
              const reviewFormState: ReviewFormPropState & ReviewPropState = {
                CreditCardInfo: null,
                ACHInfo: {
                  paymentType: data.verifyACHInformation.paymentType,
                  authTransactionId: data.verifyACHInformation.authTransactionId,
                  gatewayTokenId: data.verifyACHInformation.gatewayTokenId,
                  ACHBankName__c: bankName,
                  ACHLast6__c: lastSixDigitsAccountNumber,
                  ACHRoutingLast9__c: lastNineDigitsRoutingNumber,
                  ACH_Name_of_Account__c: AccountType,
                  Name_on_Card__c: achNameOnCard,
                },
                HSACard__c: HSA,
                token: token,
                scheduled: scheduledDate,
                // remove the dollar sign and convert to float
                amount: Amount,
                paymentMethod: paymentMethod,
                mdvipId: mdvipId,
                dob: dob,
                balance: balance,
                type: 'Bank',
                Address: Address,
                Address2: Address2,
                City: City,
                State: State,
                BillingZipCode: BillingZipCode,
                estimatedPaidThrough: estimatedPaidThrough,
                contact_id: contact_id,
              }

              navigate('/review', { state: reviewFormState })
            } else if (data.verifyACHInformation.__typename === 'VerifyACHInformationFailure') {
              setMessage(
                data.verifyACHInformation.errors.length > 0
                  ? data.verifyACHInformation.errors[0].message
                  : DEFAULT_ERROR_MESSAGE,
              )
            } else {
              setMessage(DEFAULT_ERROR_MESSAGE)
            }
          },
          onError() {
            setMessage(DEFAULT_ERROR_MESSAGE)
          },
        })
      } else if (CardNumber.length > 0 && CVV.length > 0 && ExpirationMonth.length > 0 && ExpirationYear.length > 0) {
        // Credit card was selected for the payment
        const year = parseInt(ExpirationYear.slice(-2))
        const month = parseInt(ExpirationMonth)
        const lastFourDigitsCardNumber = CardNumber.substring(CardNumber.length - 4)
        // If Credit card form fields are filled.
        const paymentMethod = cardType.toUpperCase() + ' ending in ' + CardNumber.substring(CardNumber.length - 4)

        verifyCreditCardInformation({
          variables: {
            input: { CardNumber: CardNumber, CVV: CVV, ExpirationMonth: month, ExpirationYear: year },
          },
          onCompleted(data) {
            if (data.verifyCreditCardInformation.__typename === 'VerifyCreditCardInformationSuccess') {
              navigate('/review', {
                state: {
                  ACHInfo: null,
                  CreditCardInfo: {
                    PaymentType__c: data.verifyCreditCardInformation.paymentType,
                    gatewayTokenId: data.verifyCreditCardInformation.gatewayTokenId,
                    authTransactionId: data.verifyCreditCardInformation.authTransactionId,
                    CCLast4__c: lastFourDigitsCardNumber,
                    Name_on_Card__c: ccNameOnCard,
                    Expiration_Month__c: month,
                    Expiration_Year__c: ExpirationYear,
                  },
                  HSACard__c: HSA,
                  token: token,
                  amount: Amount,
                  scheduled: scheduledDate,
                  paymentMethod,
                  dob,
                  mdvipId,
                  contact_id,
                  balance,
                  type: 'Card',
                  Address,
                  Address2,
                  City,
                  State,
                  BillingZipCode,
                  estimatedPaidThrough,
                },
              })
            } else if (data.verifyCreditCardInformation.__typename === 'VerifyCreditCardInformationFailure') {
              setMessage(
                data.verifyCreditCardInformation.errors.length > 0
                  ? data.verifyCreditCardInformation.errors[0].message
                  : DEFAULT_ERROR_MESSAGE,
              )
            } else {
              setMessage(DEFAULT_ERROR_MESSAGE)
            }
          },
          onError() {
            setMessage(DEFAULT_ERROR_MESSAGE)
          },
        })
      } else {
        setMessage('The information you entered is not valid. Please double check it and try again.')
      }
    }
  }

  const handleOnChange = (e: any) => {
    const { name, value } = e.currentTarget

    switch (name) {
      case 'BillingZipCode':
        // return error if there are special characters
        if (!/^\d{0,5}(-\d{0,4})?$/.test(value)) return
        else setFormValues({ ...formValues, [name]: value })
        break
      case 'CardNumber':
        setFormValues({ ...formValues, [name]: value })
        break
      case 'CVV':
        if (!/^\d{0,4}$/.test(value)) return
        else setFormValues({ ...formValues, [name]: value })
        break
      case 'AccountNumber':
        if (!/^\d*$/.test(value)) return
        else setFormValues({ ...formValues, [name]: value })
        break
      case 'RoutingNumber':
        if (!/^\d{0,9}$/.test(value)) return
        else setFormValues({ ...formValues, [name]: value })
        break
      default:
        setFormValues({ ...formValues, [name]: value })
    }
    if (message) {
      message && setMessage('')
      showLoader && setShowLoader(false)
    }

    const isFormCompleted = isFormComplete()
    toggleSubmitIsDisabled(isFormCompleted)
  }

  const validate = (values: PayFormInterface) => {
    const errors: PayFormInterface = {
      Amount: '',
      NameOnCard: '',
      CardNumber: '',
      CVV: '',
      ExpirationMonth: '',
      ExpirationYear: '',
      RoutingNumber: '',
      bankName: '',
      AccountNumber: '',
      Address: '',
      Address2: '',
      City: '',
      State: '',
      BillingZipCode: '',
    }
    const CityRegEx = new RegExp('^[a-zA-Z\\s-]+$')
    if (!CityRegEx.test(values.City.trim())) {
      errors.City = [errors.City, 'City should contain only characters.'].join(' ')
      setShowLoader(false)
      isSubmit = false
    }

    if (values.State.length == 0) {
      errors.State = 'State is required.'
      setShowLoader(false)
      isSubmit = false
    }
    const zipPattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    // Test Billing.
    if (!zipPattern.test(values.BillingZipCode)) {
      errors.BillingZipCode = 'Zip code format is invalid.'
      setShowLoader(false)
      isSubmit = false
    }

    if (parseInt(values.Amount) <= 0) {
      errors.Amount = 'The amount must be more than $0.'
      setShowLoader(false)
      isSubmit = false
    }

    if (data?.paymentSettings) {
      const maxAmount = data.paymentSettings.MaximumPaymentAmount?.Amount__c || 0
      const maxAmountMessage = data.paymentSettings.MaximumPaymentAmount?.Description__c + ' $' + maxAmount

      const Amount = parseFloat(values.Amount.replace('$', ''))
      if (Amount > maxAmount) {
        errors.Amount = [errors.Amount, maxAmountMessage].join(' ')
      }
    }

    if (values.CardNumber.length > 0) {
      const ExpirationMonth = expiration.month.parse(values.ExpirationMonth)
      const ExpirationYear = expiration.year.parse(values.ExpirationYear, true)
      const CVV = values.CVV

      if (expiration.isPast(ExpirationMonth, ExpirationYear)) {
        errors.ExpirationMonth = 'This card has expired. Please use a valid card.'
        setShowLoader(false)
        isSubmit = false
      }

      if (!cvc.isValid(CVV)) {
        errors.CVV = 'The CVV is not valid.'
        setShowLoader(false)
        isSubmit = false
      }
    } else {
      const routingValidation = routingNumber(values.RoutingNumber)
      const accountValidation = accountNumber(values.AccountNumber)

      if (!routingValidation.isValid) {
        errors.RoutingNumber = 'Routing Number is invalid.'
        setShowLoader(false)
        isSubmit = false
      }

      if (!accountValidation.isValid) {
        errors.AccountNumber = 'Account Number is invalid.'
        setShowLoader(false)
        isSubmit = false
      }

      if (values.AccountNumber.length < 5) {
        errors.AccountNumber = 'Account Number must have a minimum of 5 digits.'
        setShowLoader(false)
        isSubmit = false
      }
    }

    const hasNoErrors = Object.values(errors).every((error) => error === '')
    if (hasNoErrors) isSubmit = true

    return errors
  }

  // Get bank name by routing number.
  const [isActive, setisActive] = React.useState('')
  const [bankName, setBankName] = React.useState('')

  const getBankName = (e: FocusEvent<HTMLInputElement>) => {
    const RoutingNumber = e.currentTarget.value
    if (RoutingNumber.length > 0) {
      fetch('https://www.routingnumbers.info/api/name.json?rn=' + RoutingNumber)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.code == 200) {
            setFormErrors({ ...formErrors, ['bankName']: '' })
            setFormErrors({ ...formErrors, ['RoutingNumber']: '' })
            setFormValues({ ...formValues, ['bankName']: responseJson.name })
            setFormErrors({ ...formErrors, ['bankName']: '' })
            setFormErrors({ ...formErrors, ['RoutingNumber']: '' })
            setisActive('js-filled')
            message && setMessage('')
            const $bankName = document.getElementsByClassName('bank_name')
            if ($bankName !== null) {
              const errorElement = $bankName.item(0)
              if (errorElement !== null) {
                errorElement.innerHTML = ''
              }
            }
          } else {
            const response_message = responseJson.message || DEFAULT_ERROR_MESSAGE
            setFormErrors({
              ...formErrors,
              RoutingNumber: response_message === 'not found' ? 'Invalid Routing Number' : response_message,
            })
            setMessage(response_message)
          }
        })
        .catch((err) => {
          setMessage('The bank name was not found automatically please enter the bank name manually.')
        })
    }
  }

  const getCardType = (e: FocusEvent<HTMLInputElement>) => {
    const CreditCardNumber = e.currentTarget.value
    if (CreditCardNumber.length > 0) {
      const cardTypeObject = CardTypes.find((type) => type.test(CreditCardNumber, true))
      let cardType = ''
      if (cardTypeObject !== undefined && cardTypeObject.name.length > 0) {
        cardType = cardTypeObject.name
        setCardType(cardType)
        const cssClass = cardType.replace(' ', '-').toLowerCase()
        const img = document.getElementsByClassName(cssClass)
        document.querySelectorAll('.card').forEach(function (item) {
          item.classList.add('hidden')
        })
        img.item(0)?.classList.remove('hidden')
      }
      const cardNumber = card.parse(CreditCardNumber)
      setFormValues({ ...formValues, ['CardNumber']: cardNumber })
    }
  }

  const validateAmount = (e: FocusEvent<HTMLInputElement>) => {
    const Amount = parseFloat(e.currentTarget.value.replace(/^\D+|,/g, ''))
    setFormErrors({
      ...formErrors,
      Amount: '',
    })
    isSubmit = true
    let errorMessage = ''
    if (Amount <= 0) {
      errorMessage = 'The amount must be more than $0'
      isSubmit = false
    }
    if (data?.paymentSettings) {
      const maxAmount = data.paymentSettings.MaximumPaymentAmount?.Amount__c || 0
      const maxAmountMessage = data.paymentSettings.MaximumPaymentAmount?.Description__c + ' $' + maxAmount

      if (Amount > maxAmount) {
        errorMessage = maxAmountMessage
        isSubmit = false
      }
    }

    if (errorMessage.length > 0) {
      setFormErrors({
        ...formErrors,
        Amount: errorMessage,
      })
    }
  }

  const clearBankNameOnBackspace = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && bankName.length > 0) {
      setBankName('')
      setisActive('')
    }
  }

  const isFormComplete = (): boolean => {
    const isAddressFormCompleted = isAddressFormComplete()
    const isAchFormCompleted = isAchFormComplete()
    const isCreditCardFormCompleted = isCreditCardFormComplete()
    const hasErrors = formHasErrors()
    return !((isAchFormCompleted || isCreditCardFormCompleted) && isAddressFormCompleted) && !hasErrors
  }

  const isAddressFormComplete = (): boolean => {
    const addressValues = ['Address', 'City', 'State', 'BillingZipCode']
    return addressValues.every((value) => {
      const formValue = formValues[value]
      return formValue?.length > 0
    })
  }

  const isAchFormComplete = (): boolean => {
    const achValues = ['NameOnCard', 'AccountNumber', 'RoutingNumber', 'bankName']
    return achValues.every((value: string) => {
      const formValue = formValues[value]
      return formValue?.length > 0
    })
  }

  const isCreditCardFormComplete = (): boolean => {
    const creditCardValues = ['NameOnCard', 'CardNumber', 'ExpirationMonth', 'ExpirationYear', 'CVV']
    return creditCardValues.every((value) => {
      const formValue = formValues[value]
      return formValue?.length > 0 || (typeof formValue === 'number' && formValue > 0)
    })
  }

  const formHasErrors = (): boolean => {
    return Object.values(formErrors).every((error) => error.length > 0)
  }

  window.document.addEventListener('click', function () {
    const isFormCompleted = isFormComplete()
    toggleSubmitIsDisabled(isFormCompleted)
  })

  return (
    <React.Fragment>
      {(verifyAchInformationError || verifyCreditCardInformationError || verifyPaymentSettingsError) && message && (
        <Alert severity="error" variant="outlined">
          {message}
        </Alert>
      )}
      {!message && showLoader && <LoadingSpinner loading={showLoader} />}
      <form className="pay-form" onSubmit={onSubmit}>
        <div className="amount-label">Enter Payment Amount</div>
        <InputPayment
          name="Amount"
          className={formErrors.Amount ? 'element-error js-filled' : Number(balance) > 0 ? 'js-filled' : ''}
          onChange={handleOnChange}
          onBlur={validateAmount}
          required={true}
          label="Payment Amount ($)"
          type="number"
          step={1}
          defaultValue={formValues.Amount ? formValues.Amount : Number(balance) > 0 ? balance : ''}
          requiredMessage={formErrors.Amount}
        />
        <div className="input-description">
          Payment will be scheduled for {scheduledDateFormatted}. To schedule payment for a future date, please{' '}
          <a href={MDVIP_LOGIN_LINK} target="_blank" rel="noreferrer noopener">
            login
          </a>{' '}
          to your account.
        </div>
        <div className="billing-section">
          <hr />
          <div className="label">Enter Billing Address</div>
          <Input
            name="Address"
            privateData={true}
            className={formErrors.Address ? 'element-error js-filled' : formValues.Address ? ' js-filled' : ''}
            onChange={handleOnChange}
            required={true}
            label="Address"
            type="text"
            maxLength={255}
            defaultValue={formValues.Address}
            requiredMessage={formErrors.Address}
          />
          <Input
            name="Address2"
            privateData={true}
            className={formErrors.Address2 ? 'element-error js-filled' : formValues.Address2 ? ' js-filled' : ''}
            onChange={handleOnChange}
            label="Address 2"
            type="text"
            maxLength={255}
            defaultValue={formValues.Address2}
          />
          <Input
            name="City"
            className={formErrors.City ? 'element-error js-filled' : formValues.City ? ' js-filled' : ''}
            onChange={handleOnChange}
            required={true}
            label="City"
            type="text"
            maxLength={25}
            defaultValue={formValues.City}
            requiredMessage={formErrors.City}
          />
          <div className="billing-state-zip">
            <Select
              name="State"
              classNamePrefix="select"
              placeholder="State"
              className="select-state-required select-field--wrapper"
              options={STATES}
              onChange={(e: any) => setFormValues({ ...formValues, State: e.value })}
              value={STATES.filter(function (state: { label: string; value: string }) {
                return state.value === formValues.State
              })}
            />
            <Input
              name="BillingZipCode"
              className={
                formErrors.BillingZipCode.length > 0
                  ? 'element-error js-filled'
                  : formValues.BillingZipCode
                  ? ' js-filled'
                  : ''
              }
              onChange={handleOnChange}
              required={true}
              label="Zip Code"
              type="text"
              value={formValues.BillingZipCode || ''}
              requiredMessage={formErrors.BillingZipCode}
            />
          </div>
        </div>
        <hr />
        <InformationSecure />
        {!message && showLoader ? <LoadingSpinner loading={showLoader} /> : <div className="error">{message}</div>}
        <div className="options">
          <Tabs>
            <TabList>
              <Tab>
                <CheckIcon />
                Credit Card
              </Tab>
              <Tab>
                <CheckIcon />
                Bank Account
              </Tab>
            </TabList>
            <TabPanel>
              <div className="bank-account-label pb-3">Credit Card Info</div>
              <Input
                name="NameOnCard"
                privateData={true}
                className={
                  formErrors.NameOnCard ? 'element-error js-filled' : formValues.NameOnCard ? ' js-filled' : ''
                }
                onChange={handleOnChange}
                required={true}
                label="Name on Card"
                type="text"
                defaultValue={formValues.NameOnCard}
                maxLength={55}
                requiredMessage={formErrors.NameOnCard}
              />
              <div className="d-flex card-number">
                <CreditCardInput
                  name="CardNumber"
                  privateData={true}
                  className={
                    formErrors.CardNumber || (message && message.includes('Card Number'))
                      ? 'element-error js-filled'
                      : formValues.CardNumber
                      ? ' js-filled'
                      : ''
                  }
                  onChange={handleOnChange}
                  required={true}
                  label="Card Number"
                  onBlur={getCardType}
                  requiredMessage={formErrors.CardNumber}
                />
                <div className="card-icons">
                  <img src={`${ASSETS_URL}/assets/images/visa.svg`} alt="Visa" className="visa card" />
                  <img
                    src={`${ASSETS_URL}/assets/images/mastercard.svg`}
                    alt="Master Card"
                    className="mastercard card"
                  />
                  <img
                    src={`${ASSETS_URL}/assets/images/american-express.svg`}
                    alt="American Express"
                    className="american-express card"
                  />
                  <img src={`${ASSETS_URL}/assets/images/discover.svg`} alt="Discover" className="discover card" />
                </div>
              </div>
              <div className="d-flex payment-code-expiration">
                <div className="d-inline-flex">
                  <ConcealedNumber
                    name="CVV"
                    privateData={true}
                    onChange={handleOnChange}
                    required={true}
                    label="Security Code"
                    maxLength={4}
                    pattern="[0-9]"
                    value={formValues.CVV}
                    className={formErrors.CVV ? 'element-error js-filled' : formValues.CVV ? ' js-filled' : ''}
                    requiredMessage={formErrors.CVV}
                  />
                  <div className="secure-info d-flex py-3">
                    <InfoOutline text="A credit card security code is a 3- or 4-digit number that is printed on all credit cards, separate from the card number." />
                  </div>
                </div>
                <DropDown
                  name="ExpirationMonth"
                  privateData={true}
                  required={true}
                  label="Exp Date (MM)"
                  className="select-exp-required select-field--wrapper"
                  selectValue={MONTHS.filter(function (state: { value: number; label: string }) {
                    return state.value === parseInt(formValues.ExpirationMonth)
                  })}
                  onChange={(e: any) => setFormValues({ ...formValues, ExpirationMonth: e.value })}
                  options={MONTHS}
                  requiredMessage={formErrors.ExpirationMonth}
                />
                <DropDown
                  name="ExpirationYear"
                  privateData={true}
                  required={true}
                  label="Exp Date (YY)"
                  className="select-exp-required select-field--wrapper"
                  options={years}
                  requiredMessage={formErrors.ExpirationYear}
                  onChange={(e: any) => setFormValues({ ...formValues, ExpirationYear: e.value })}
                  selectValue={years.filter(function (state: Option) {
                    return state.value === parseInt(formValues.ExpirationYear)
                  })}
                />
              </div>
              <div className="hsa-container">
                <div className="hsa-label required">Health Savings Account</div>
                <Radio
                  name="HSA"
                  id="hsa-yes"
                  label="Yes"
                  defaultValue="Yes"
                  onChange={handleOnChange}
                  required={true}
                />
                <Radio id="hsa-no" label="No" defaultValue="No" onChange={handleOnChange} required={true} name="HSA" />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bank-account-label pb-3">Bank Account Info</div>

              <div className="bank-account-type-label py-4">Account Type</div>

              <Tabs>
                <TabList>
                  <Tab>
                    <CheckIcon />
                    Checking
                  </Tab>
                  <Tab>
                    <CheckIcon />
                    Savings
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="routing-number-disclaimer">
                    <RoutingToolTip text="Where to find routing and account number" />
                  </div>
                  <Input
                    name="NameOnCard"
                    privateData={true}
                    className={
                      formErrors.NameOnCard ? 'element-error js-filled' : formValues.NameOnCard ? ' js-filled' : ''
                    }
                    onChange={handleOnChange}
                    required={true}
                    label="Name on Card"
                    type="text"
                    defaultValue={formValues.NameOnCard}
                    maxLength={55}
                    requiredMessage={formErrors.NameOnCard}
                  />
                  <ConcealedNumber
                    pattern="[0-9]"
                    name="RoutingNumber"
                    privateData={true}
                    onChange={handleOnChange}
                    required={true}
                    label="Routing Number"
                    onBlur={getBankName}
                    type="text"
                    value={formValues.RoutingNumber}
                    maxLength={9}
                    onKeyDown={(e) => clearBankNameOnBackspace(e)}
                    className={
                      formErrors.RoutingNumber
                        ? 'element-error js-filled'
                        : formValues.RoutingNumber
                        ? ' js-filled'
                        : ''
                    }
                    requiredMessage={formErrors.RoutingNumber}
                  />
                  <Input
                    name="bankName"
                    privateData={true}
                    required={true}
                    label="Bank Name"
                    value={formValues.bankName}
                    type="text"
                    onChange={handleOnChange}
                    className={
                      formErrors.bankName ? isActive + ' element-error js-filled' : isActive ? ' js-filled' : ''
                    }
                    requiredMessage={formErrors.bankName}
                  />
                  <ConcealedNumber
                    pattern="[0-9]"
                    name="AccountNumber"
                    privateData={true}
                    onChange={handleOnChange}
                    required={true}
                    label="Account Number"
                    type="text"
                    value={formValues.AccountNumber}
                    maxLength={17}
                    className={
                      formErrors.AccountNumber
                        ? 'element-error js-filled'
                        : formValues.AccountNumber
                        ? ' js-filled'
                        : ''
                    }
                    requiredMessage={formErrors.AccountNumber}
                  />
                  <Input privateData={true} name="Checking" label="" type="hidden" value="1" />
                  <div className="hsa-container">
                    <div className="hsa-label required">Health Savings Account</div>
                    <Radio
                      id="hsa-yes"
                      label="Yes"
                      defaultValue="Yes"
                      onChange={handleOnChange}
                      required={true}
                      name="HSA"
                    />
                    <Radio
                      id="hsa-no"
                      label="No"
                      defaultValue="No"
                      onChange={handleOnChange}
                      required={true}
                      name="HSA"
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="routing-number-disclaimer">
                    <RoutingToolTip text="Where to find routing and account number" />
                  </div>
                  <Input
                    name="NameOnCard"
                    privateData={true}
                    className={
                      formErrors.NameOnCard ? 'element-error js-filled' : formValues.NameOnCard ? ' js-filled' : ''
                    }
                    onChange={handleOnChange}
                    required={true}
                    label="Name on Card"
                    type="text"
                    defaultValue={formValues.NameOnCard}
                    maxLength={55}
                    requiredMessage={formErrors.NameOnCard}
                  />
                  <ConcealedNumber
                    pattern="[0-9]"
                    name="RoutingNumber"
                    privateData={true}
                    onChange={handleOnChange}
                    required={true}
                    label="Routing Number"
                    onBlur={getBankName}
                    type="text"
                    value={formValues.RoutingNumber}
                    maxLength={9}
                    onKeyDown={(e) => clearBankNameOnBackspace(e)}
                    className={
                      formErrors.RoutingNumber
                        ? 'element-error js-filled'
                        : formValues.RoutingNumber
                        ? ' js-filled'
                        : ''
                    }
                    requiredMessage={formErrors.RoutingNumber}
                  />
                  <Input
                    name="bankName"
                    privateData={true}
                    required={true}
                    label="Bank Name"
                    value={formValues.bankName}
                    type="text"
                    onChange={handleOnChange}
                    className={
                      formErrors.bankName ? isActive + ' element-error js-filled' : isActive ? ' js-filled' : ''
                    }
                    requiredMessage={formErrors.bankName}
                  />
                  <ConcealedNumber
                    pattern="[0-9]"
                    name="AccountNumber"
                    privateData={true}
                    onChange={handleOnChange}
                    required={true}
                    label="Account Number"
                    type="text"
                    value={formValues.AccountNumber}
                    maxLength={17}
                    className={
                      formErrors.AccountNumber
                        ? 'element-error js-filled'
                        : formValues.AccountNumber
                        ? ' js-filled'
                        : ''
                    }
                    requiredMessage={formErrors.AccountNumber}
                  />
                  <Input privateData={true} name="Checking" label="" type="hidden" value="0" />
                  <div className="hsa-container">
                    <div className="hsa-label required">Health Savings Account</div>
                    <Radio
                      id="hsa-yes"
                      label="Yes"
                      defaultValue="Yes"
                      onChange={handleOnChange}
                      required={true}
                      name="HSA"
                    />
                    <Radio
                      id="hsa-no"
                      label="No"
                      defaultValue="No"
                      onChange={handleOnChange}
                      required={true}
                      name="HSA"
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </TabPanel>
          </Tabs>
        </div>
        <Submit id="payment-review" value="Review" onClick={onSubmit} disabled={isSubmitButtonDisabled} />
        {message && <ErrorText error={message} />}
      </form>
    </React.Fragment>
  )
}

export default PayForm
